import React from "react";

// components
import { graphql, useStaticQuery, Link } from "gatsby";
import SecondaryLanding from "../../../../components/layouts/secondary-landing";
import Hero from "../../../../components/hero/hero";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SEO from "../../../../components/seo/seo";
import MktoForm from "../../../../components/mkto-form/mkto-form";

//helper functions
import showMktoForm from "../../../../helpers/showMktoForm";
import checkMktoFormIsSubmitted from "../../../../helpers/checkMktoFormIsSubmitted";
import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";

const QwikFi = () => {
  const isMktoFormSubmitted = checkMktoFormIsSubmitted();
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-2500.jpg" }) {
        ...heroImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-1920.jpg" }) {
        ...heroImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-1200.jpg" }) {
        ...heroImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-992.jpg" }) {
        ...heroImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-768.jpg" }) {
        ...heroImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-600.jpg" }) {
        ...heroImageFragmentXS
      }
    }
  `);

  const heroData = {
    id: "qwikfi-hero",
    ...getHeroImgVariables(imgData, ["xs", "sm", "md", "lg", "xl", "xxl"]),
    altText: "",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Lower Your Mortgage Interest Rate Without Refinancing with WaFd Bank QwikFi"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      url: "/personal-banking/home-loans/homeowner-assistance",
      title: "Homeowner Assistance"
    },
    {
      id: 4,
      active: true,
      title: "WaFd QwikFi"
    }
  ];

  const SEOData = {
    title: "WaFd Bank QwikFi - Lower Interest Rate Without Refinancing",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "WaFd Bank QwikFi - Lower Interest Rate Without Refinancing"
      },
      {
        name: "description",
        content:
          "Do you have an existing WaFd Bank mortgage loan and are looking to lower your interest rate without refinancing? Learn more about our QwikFi program here."
      },
      {
        property: "og:title",
        content: "WaFd Bank QwikFi - Lower Interest Rate Without Refinancing"
      },
      {
        property: "og:description",
        content:
          "Do you have an existing WaFd Bank mortgage loan and are looking to lower your interest rate without refinancing? Learn more about our QwikFi program here."
      },
      {
        property: "og:url",
        content:
          "https://www.wafdbank.com/personal-banking/home-loans/homeowner-assistance/qwikfi-lower-mortgage-interest-rate-without-refinancing"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Hero {...heroData} />
      <Breadcrumb data={breadcrumbData} />
      <MktoForm />
      <section className="container">
        <h2>Thinking about refinancing your mortgage?</h2>
        <h1>Talk to us first about a WaFd QwikFi</h1>
        <p>
          As a portfolio lender, WaFd Bank has the flexibility to help our current borrowers with a fast-track path to
          reduce the interest rate on an existing WaFd Bank mortgage without extending the loan term. It's quick,
          convenient and easier than refinancing. It's a QwikFi!
        </p>
      </section>
      <section className="bg-light">
        <div className="container text-center">
          {isMktoFormSubmitted ? (
            <h2>Thank you for submitting your request</h2>
          ) : (
            <>
              <h2>Request a QwikFi Consultation</h2>
              <button
                id="qwikfi-mk-btn"
                className="btn btn-primary my-3"
                type="button"
                onClick={() => showMktoForm(1132)}
              >
                Request Consultation
              </button>
            </>
          )}
        </div>
      </section>
      <section className="container">
        <table className="table table-bordered cols-2 mb-5">
          <tbody>
            <tr>
              <td className="align-middle text-right">
                <strong>WaFd QwikFi features</strong>
              </td>
              <td>
                <ul>
                  <li>Complete your request over the phone with your local banker by requesting a consultation.</li>
                  <li>QwikFi requires a simple request form rather than a full mortgage application.</li>
                  <li>Enjoy ZERO out of pocket costs.</li>
                  <li>You lock in your rate for 30 days starting the day you submit a complete QwikFi request.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="align-middle text-right">
                <strong>Eligibility</strong>
              </td>
              <td>
                <p>
                  <strong>To be eligible for the QwikFi program, you must meet the following criteria:</strong>
                </p>
                <ul>
                  <li>Hold an existing WaFd mortgage in good standing with no late payments.</li>
                  <li>Retain your fixed mortgage with a term of either 30 or 15 years.</li>
                  <li>Carry a Loan-to-Value of 80% of less.</li>
                  <li>Use the financed property as your primary or second home.</li>
                  <li>Ask us about WaFd-financed investment properties as some may qualify.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="align-middle text-right">
                <strong>Not Eligible</strong>
              </td>
              <td>
                <p>
                  <strong>
                    The following are not eligible for the QwikFi program, but may qualify for our other modification
                    options:
                  </strong>
                </p>
                <ul>
                  <li>HELOCs</li>
                  <li>Lot loans with interest-only payments</li>
                  <li>Foreign or Canadian National Loans</li>
                  <li>Custom construction loans that are not yet completed or converted to permanent financing</li>
                  <li>Personal loans or lines of credit</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-center">
          If you have any questions regarding our loan programs, including the WaFd QwikFi, please{" "}
          <Link to="/locations">contact your local branch</Link>.
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default QwikFi;
